import { DASHBOARD_FETCH_BEGIN, DASHBOARD_FETCH_FAILURE } from "../actions/dashboardActions";

const initialState = {
    boxInfo: {},
    top_sales_products: [],
    pending_orders: [],
    pro_rec: []
};

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_FETCH_BEGIN:
            return {
                ...state,
                boxInfo: action.boxInfo,
                top_sales_products: action.top_sales_products,
                pending_orders: action.pending_orders,
                pro_rec: action.pro_rec
            };

        case DASHBOARD_FETCH_FAILURE:
            return {
                ...state,
                ...initialState
            };

        default:
            return state;
    }
}