import React from 'react';
import FormContext from './Context';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MultiSelect = ({ label, value, name, options, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return (
        <FormContext.Consumer>
            {context => {
                const errors = context? context.errors: {};
                const errMsgShow = context? context.errMsgShow: true;
                return <FormControl fullWidth>
                    <InputLabel id={label}>{label}</InputLabel>
                    <Select
                        labelId={label}
                        multiple
                        value={value || ''}
                        name={name}
                        fullWidth
                        onChange={changeHandler}
                        input={<OutlinedInput label={label} />}
                        renderValue={(selected) => `${selected.length} Selected`}
                        error={errors && errors.details && errors.details[name]? true: false}
                        {...props}
                        MenuProps={MenuProps} >
                        {options.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                <Checkbox checked={value.indexOf(option.value) > -1} />
                                <ListItemText primary={option.title} />
                            </MenuItem>
                        ))}
                    </Select>
                    {(errMsgShow && errors.details && errors.details[name]) && 
                        <FormHelperText error>
                            {errors.details[name]}
                        </FormHelperText>
                    }
                </FormControl>
            }}
        </FormContext.Consumer>
    );
}
export default MultiSelect;