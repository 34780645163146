// import typography from './typography';
import { createTheme } from '@mui/material/styles';

// import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

function MuiTheme(theme = 'light') {
    const PrimaryColors = {
        'light': '#1976d2',
        'dark': '#FFFFFF',
        'blood': '#B20600',
        'water': '#82C3EC',
        'forest': '#285430',
    }
    
    return createTheme({
        palette: {
            primary: { main: PrimaryColors[theme] },
        }
    })
}
// const MuiTheme = createTheme({
// palette: {
//     primary: { main: '#FFFFFF' },
//     primary: { main: '#1976d2' },
//     mode: 'dark',
//     primary: { main: vars.primary },
//     grey: { 300: vars.inheritDefault1, A100: vars.inheritDefault2 },
//     secondary: { main: vars.secondary },
//     error: { main: vars.red },
//     success: { main: vars.green },
//     warning: { main: vars.orange },
//     helpers: { primary: vars.blue, main: 'rgba(25, 46, 91, .035)' },
//     contrastThreshold: 3, tonalOffset: 0.1
// },
// shape: { borderRadius: '0.5rem' },
// overrides: {
//     MuiButton: {
//         text: { paddingLeft: '14px', paddingRight: '14px' },
//         containedSizeSmall: { paddingLeft: '14px', paddingRight: '14px' },
//         root: { textTransform: 'none', fontWeight: 'normal' }
//     },
//     MuiTooltip: {
//         tooltip: { backgroundColor: vars.second, padding: '8px 16px', fontSize: '13px' },
//         arrow: { color: vars.second }
//     },
//     MUIRichTextEditor: {
//         root: {
//             margin: '5px'
//         },
//         editor: {
//             padding: "20px",
//             height: "150px",
//             maxHeight: "150px",
//             overflow: "auto",
//             borderBottom: "1px solid #0000003b",
//             borderLeft: "1px solid #0000003b",
//             borderRight: "1px solid #0000003b",
//             borderBottomLeftRadius: '0.5rem',
//             borderBottomRightRadius: '0.5rem',
//         },
//         toolbar: {
//             border: "1px solid #0000003b",
//             backgroundColor: "#ebebeb",
//             borderTopLeftRadius: '0.5rem',
//             borderTopRightRadius: '0.5rem',
//         },
//         placeHolder: {
//             padding: '16.5px 8px',
//             margin: '0px',
//             borderLeft: "1px solid #0000003b",
//             borderRight: "1px solid #0000003b",
//             width: "inherit",
//             color: '#00000099'
//         },
//         anchorLink: {
//             color: "#333333",
//             textDecoration: "underline"
//         }
//     }
// },
// typography
// });

export default MuiTheme;
