import React, { Fragment } from 'react';
import ListIcon from '@mui/icons-material/List';
import { Hidden, IconButton, Box, Tooltip } from '@mui/material';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';

function HeaderDrawer() {

    const [open, setOpen] = React.useState(false);

    function handleDrawerClose() {
        setOpen(true);
    }

    return (
        <Fragment>
            <Hidden smDown>
                <Box className="ml-2">
                    <Tooltip title="Toggle Drawer" placement="right">
                        <IconButton color="inherit" onClick={handleDrawerClose} size="medium" className="btn-inverse">
                            {open ? <MenuOpenRoundedIcon /> : <ListIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Hidden>
        </Fragment>
    );
}

export default HeaderDrawer;
