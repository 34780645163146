import { SET_ERROR } from "../actions/errorActions";

const initialState = { appError: "" };

export default function errorReducer(state = initialState, action) {

    switch (action.type) {
        case SET_ERROR:
            return { ...state, appError: action.error };

        default:
            return state;
    }
}