import { USER_FETCH_BEGIN, USER_FETCH_SUCCESS, USER_FETCH_FAILURE } from "../actions/userActions";

const initialState = {
    user: {},
    authenticating: false,
    error: null
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case USER_FETCH_BEGIN:
            return {
                ...state,
                user: action.user,
                authenticating: true,
                error: null
            };

        case USER_FETCH_SUCCESS:
            return {
                ...state,
                authenticating: false,
                user: action.payload.user
            };

        case USER_FETCH_FAILURE:
            return {
                ...state,
                authenticating: false,
                error: true
            };

        default:
            return state;
    }
}