import clsx from 'clsx';
import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import SmallLogo from '../../assets/images/logo/deeptrace01.png'
import Logo from '../../assets/images/logo/deeptracelogo.png'

const HeaderLogo = props => {
    const { sidebarToggle, user } = props;

    return (
        <Fragment>
            <div className={clsx('app-header-logo', { 'app-header-logo-close': sidebarToggle })}>
                <Box className="header-logo-wrapper" title="Deeptrace">
                    <Link to="/dashboard" className="header-logo-wrapper-link">
                        {/* header-logo-wrapper-btn */}
                        <IconButton color="primary" size="medium" className="">
                            {sidebarToggle ?
                                <img
                                    className="app-header-logo-img"
                                    style={{ width: '100%', height: 'auto' }}
                                    alt="WINT"
                                    crossOrigin="anonymous"
                                    // src={user.logo ? user.logo : SmallLogo}
                                    src={SmallLogo}
                                /> :
                                <img
                                    className="app-header-logo-img w-100"
                                    alt="WINT"
                                    crossOrigin="anonymous"
                                    // src={user.logo ? user.logo : Logo}
                                    src={Logo}
                                />
                                // 'DeepTrace'
                            }
                        </IconButton>
                    </Link>
                </Box>
            </div>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    user: state.user.user,
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);