import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { ListItem, Button, Collapse } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useState, forwardRef, useEffect } from 'react';

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
    return (
        <div ref={ref} style={{ flexGrow: 1 }}>
            <RouterLink {...props} />
        </div>
    );
});

const SidebarMenuListItem = props => {
    // keep CRM menu active and sub menu selected
    let currentPath = window.location.pathname
    currentPath = currentPath.includes('/crm/company/employee/edit') ? '/crm/company/employee/edit' : currentPath
    currentPath = currentPath.includes('/crm/company/roles/edit') ? '/crm/company/roles/edit' : currentPath
    currentPath = currentPath.includes('/crm/company/permissions/edit') ? '/crm/company/permissions/edit' : currentPath
    const crmUrl = ['/crm/company/employee/invite', '/crm/company/employee/edit', '/crm/company/roles/list', '/crm/company/roles/create','/crm/company/roles/edit', '/crm/company/permissions/list','/crm/company/permissions/edit']
    const activeCRM = (props.title === 'Company' && crmUrl.includes(currentPath)) ? 'active-item' : ''

    const {
        title,
        href,
        depth,
        children,
        icon: Icon,
        className,
        open: openProp,
        label: Label,
        ...rest
    } = props;
    const [open, setOpen] = useState(openProp);
    const [Count, setCount] = useState(0);

    useEffect(() => {
        let isOpen = false;
        if (children) {
            const find = children.props.children.props.pages.find((path) => {
                return path.to === window.location.pathname;
            });
            if (find !== undefined) {
                isOpen = true;
            }
        }

        if (title === 'CRM' && crmUrl.includes(currentPath)) {
            isOpen = true
        }

        setOpen((open) => isOpen);
    }, [Count]);

    const handleToggle = () => setOpen(open => !open)

    let paddingLeft = 14;

    if (depth > 0) {
        paddingLeft = 20 + 5 * depth;
    }
    const style = { paddingLeft };

    if (children) {
        return (
            <ListItem {...rest} className={clsx('app-sidebar-item', className)} disableGutters>
                <Button color="primary" className={clsx('app-sidebar-button', { active: open })} onClick={handleToggle} style={style}>
                    {Icon && <Icon className="app-sidebar-icon" />}
                    <span>{title}</span>
                    {open ? (
                        <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
                    ) : (
                        <ExpandLessIcon
                            className="sidebar-expand-icon sidebar-expand-icon-rotate"
                            color="inherit"
                        />
                    )}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    } else {
        return (
            <ListItem {...rest} className={clsx('app-sidebar-item', className)} disableGutters>
                <Button
                    activeClassName="active-item"
                    color="primary"
                    disableRipple
                    variant="text"
                    onClick={handleToggle}
                    className={`${activeCRM} app-sidebar-button-wrapper depth-${depth}`}
                    // className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
                    // + (crmUrl.includes(window.location.pathname) ? ' active-item' : '')
                    component={CustomRouterLink}
                    exact
                    style={style}
                    to={href}>
                    {Icon && <Icon className="app-sidebar-icon" />}
                    {title}
                    {Label && (
                        <span className="menu-item-label">
                            <Label />
                        </span>
                    )}
                </Button>
            </ListItem>
        );
    }
};

SidebarMenuListItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    label: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

SidebarMenuListItem.defaultProps = {
    depth: 0,
    open: false
};

export default SidebarMenuListItem;