import CodeIcon from '@mui/icons-material/Code';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SecurityIcon from '@mui/icons-material/Security';
import ApprovalIcon from '@mui/icons-material/Approval';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useTranslation } from 'react-i18next';

var iconsMap = {
    ChatIcon: ChatIcon,
    CodeIcon: CodeIcon,
    MailIcon: MailIcon,
    HomeIcon: HomeIcon,
    ErrorIcon: ErrorIcon,
    FolderIcon: FolderIcon,
    PeopleIcon: PeopleIcon,
    PersonIcon: PersonIcon,
    ListAltIcon: ListAltIcon,
    ReceiptIcon: ReceiptIcon,
    BarChartIcon: BarChartIcon,
    LockOpenIcon: LockOpenIcon,
    GradeTwoTone: GradeTwoTone,
    SettingsIcon: SettingsIcon,
    DashboardIcon: DashboardIcon,
    ViewModuleIcon: ViewModuleIcon,
    PresentToAllIcon: PresentToAllIcon,
    CalendarTodayIcon: CalendarTodayIcon,
    DashboardTwoToneIcon: DashboardTwoToneIcon,
    WarehouseIcon: WarehouseIcon,
    SecurityIcon: SecurityIcon,
    ApprovalIcon: ApprovalIcon,
    GroupAddIcon: GroupAddIcon,
    ManageAccountsIcon: ManageAccountsIcon
};
function navItems(permissions, app_user) {
    const { t } = useTranslation(['modules']);

    let menus = [
        {
            label: t('modules:home'),
            icon: "HomeIcon",
            to: "/dashboard"
        }
    ]

    if (app_user !== 3) {
        if (permissions.includes("manage-company") || permissions.includes("read-role") || permissions.includes("assign-permission") || app_user === 1) {
            menus = [...menus, {
                label: t('modules:global_settings'),
                icon: "SettingsIcon",
                content: []
            }]
        }
        if (permissions.includes("manage-company") || app_user === 1) {
            menus[1]['content'].push({
                label: t('modules:company_management'),
                icon: "WarehouseIcon",
                to: "/company",
            })
        }
        if (permissions.includes("read-role") || app_user === 1) {
            menus[1]['content'].push({
                label: t('modules:roles_management'),
                icon: "ApprovalIcon",
                to: "/role"
            })
        }
        if (permissions.includes("assign-permission") || app_user === 1) {
            menus[1]['content'].push({
                label: t('modules:permission_management'),
                icon: "SecurityIcon",
                to: "/permission"
            })
        }
        if (permissions.includes("read-user") || app_user === 1) {
            menus = [...menus, {
                label: t('modules:user_management'),
                icon: "PeopleIcon",
                content: [{
                    label: t('modules:users'),
                    icon: "PeopleIcon",
                    to: "/user"
                }, {
                    label: t('modules:user_invites'),
                    icon: "GroupAddIcon",
                    to: "/user/invitations"
                }]
            }]
        }
        // if (permissions.includes("update-tracking-id") || app_user === 1) {
        //     menus = [...menus, {
        //         label: t('modules:tracking_id_changes'),
        //         to: "/file/create"
        //     }]
        // }
        // if (permissions.includes("read-hold-queues") || app_user === 1) {
        //     menus = [...menus, {
        //         label: t('modules:hold_queue'),
        //         to: "/hold-queue"
        //     }]
        // }
    }

    if (app_user === 3) {
        menus = [...menus, {
            label: t('modules:crm'),
            icon: "ManageAccountsIcon",
            content: [{
                label: t('modules:company'),
                icon: "WarehouseIcon",
                to: "/crm/company/employee/list"
            }]
        }]
    }

    // if (permissions.includes("read-aldi-return-order") || app_user === 1) {
    //     menus = [...menus, {
    //         label: 'ALDI Return Order',
    //         icon: "CalendarTodayIcon",
    //         to: "/aldi-return-order"
    //     }]
    // }

    return [{
        content: JSON.parse(JSON.stringify(menus),
            (key, value) => {
                if (key === 'icon') {
                    return iconsMap[value];
                } else {
                    return value;
                }
            }
        )
    }]
}
export default navItems;