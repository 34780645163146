import React from 'react';
import './tableskeleton.css';

const TableSkeleton = props => {
    let columns = props.columns? props.columns: 4;
    let rows = props.rows? props.rows: 4;

    const populateHeader = () => {
        let header = [];
        for(let i = 0; i < columns; i++) {
            header.push(
                <th className="tg-cly1" key={i}>  
                    <div className="line"></div>
                </th>
            )
        }
        return header;
    }

    const populateRows = () => {
        let row = [];
        for(let i = 0; i < rows; i++) {
            row.push(
                <tr key={i}>
                    {populateColumns()}
                </tr>
            )
        }
        return row;
    }

    const populateColumns = () => {
        let cols = []
        for(let i = 0; i < columns; i++) {
            cols.push(
                <td className="tg-cly1" key={i}>
                    <div className="line"></div>
                </td>
            )
        }
        return cols;
    }

    return (
        <div className="table-responsive">
            <table className="tg">
                <thead>
                    <tr>
                        {populateHeader()}
                    </tr>
                </thead>
                <tbody>
                    {populateRows()}
                </tbody>
            </table>
        </div>
    )
}
export default TableSkeleton