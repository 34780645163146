export const DASHBOARD_FETCH_BEGIN = "DASHBOARD_FETCH_BEGIN";
export const DASHBOARD_FETCH_FAILURE = "DASHBOARD_FETCH_FAILURE";

export const fetchDashboardBegin = dashboard => ({
    type: DASHBOARD_FETCH_BEGIN, ...dashboard
});

export const fetchDashboardFailure = error => ({
    type: DASHBOARD_FETCH_FAILURE,
    payload: { error }
});