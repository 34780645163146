import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LazyImportPlugin from './LazyImportPlugin';
import LanguageDetector from 'i18next-browser-languagedetector';

function i18nf(lang = 'en'){
    i18n.use(LanguageDetector)
    .use(LazyImportPlugin)
    .use(initReactI18next)
    .init({
        lng: lang,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        ns: ['common'],
        saveMissing: true, // for missing key handler to fire
        missingKeyHandler: function (lng, ns, key, fallbackValue) {
            // console.log(lng, ns, key, fallbackValue);
        }
    });
    return i18n
}

export default i18nf;