import clsx from 'clsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Fragment, Suspense } from 'react';
import { Sidebar, Header, Footer } from '../../layout';

const LeftSidebar = props => {
    const { children, sidebarToggle, sidebarFixed, footerFixed, contentBackground } = props;

    return (
        <Fragment>
            <div className={clsx('app-wrapper', contentBackground)}>
                <Suspense fallback={<div></div>}>
                    <Header />
                </Suspense>
                <div className={clsx('app-main', { 'app-main-sidebar-static': !sidebarFixed })}>
                    <Suspense fallback={<div></div>}>
                        <Sidebar />
                    </Suspense>
                    <div className={clsx('app-content', {
                        'app-content-sidebar-collapsed': sidebarToggle,
                        'app-content-sidebar-fixed': sidebarFixed,
                        'app-content-footer-fixed': footerFixed
                    })}>
                        <div className="app-content--inner">
                            <div className="app-content--inner__wrapper">{children}</div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

LeftSidebar.propTypes = { children: PropTypes.node };

const mapStateToProps = state => ({
    headerFixed: state.ThemeOptions.headerFixed,
    footerFixed: state.ThemeOptions.footerFixed,
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    headerSearchHover: state.ThemeOptions.headerSearchHover,
    contentBackground: state.ThemeOptions.contentBackground,
    headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

export default connect(mapStateToProps)(LeftSidebar);