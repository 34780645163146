import React from 'react';
import { Button, Dialog } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteDialog = props => {
    const { t } = useTranslation();

    return <Dialog open={props.isOpen} onClose={props.onClose}>
        <div className="text-center p-5">
            <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-first text-first m-0 d-130" style={{ fontSize: '100px' }}>
                    {/* <FontAwesomeIcon icon={['far', 'keyboard']} className="d-flex align-self-center display-3" /> */}
                    <DeleteIcon fontSize={'inherit'} className="d-flex align-self-center display-3" />
                </div>
            </div>
            <h4 className="font-weight-bold mt-4">{props.title}</h4>
            <div className="pt-4">
                <Button onClick={props.confirmDialog} color="primary" variant="contained" className="mx-1">
                    <span className="btn-wrapper--label">
                        {props.meta && props.meta.confirmTitle ? props.meta.confirmTitle : t('delete')}
                    </span>
                </Button>
                <Button onClick={props.onClose} variant="outlined" color="secondary" className="text-danger mx-1">
                    <span className="btn-wrapper--label">{t('cancel')}</span>
                </Button>
            </div>
        </div>
    </Dialog>
}
export default DeleteDialog;