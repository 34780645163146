import './assets/base.scss';
import Routes from './routes';
import { Provider } from 'react-redux';
import React, { useEffect } from 'react';
import ScrollToTop from './utils/ScrollToTop';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import configureStore from './config/configureStore';
import { ConfirmProvider } from 'material-ui-confirm';
import { library } from '@fortawesome/fontawesome-svg-core';
import './App.css';
import './dark.css';
import './blood.css';
import './water.css';
import './forest.css';
import { fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest,
    faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';

import { far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments,
    faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt,
    faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle,
    faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode,
    faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';

import { fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight,
    faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare,
    faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager,
    faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload,
    faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp,
    faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH,
    faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft,
    faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';

library.add( far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen,
    faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard,
    faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding,
    faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar );

library.add( fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube,
    faDiscord, faSlack, faDribbble, faGithub );

library.add( fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight,
    faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle,
    faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager, faCameraRetro, faBomb,
    faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply,
    faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes,
    faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft,
    faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink );


const store = configureStore();
function App() {
    const clearCacheData = () => caches.keys().then((names) => names.forEach(name => caches.delete(name)))
    
    useEffect(() => clearCacheData(), []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <CssBaseline />
                <ScrollToTop>
                    <ConfirmProvider>
                        <Routes />
                        <ToastContainer position="top-right"
                            autoClose={3000}
                            hideProgressBar={true}
                            closeOnClick={true}
                            pauseOnHover={true}
                            draggable={true} />
                    </ConfirmProvider>
                </ScrollToTop>
            </BrowserRouter>
        </Provider>
    );
}

export default App;