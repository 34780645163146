export const USER_FETCH_BEGIN = "USER_FETCH_BEGIN";
export const USER_FETCH_SUCCESS = "USER_FETCH_SUCCESS";
export const USER_FETCH_FAILURE = "USER_FETCH_FAILURE";

/** returns action for user fetch begin */
export const fetchUserBegin = user => ({
    type: USER_FETCH_BEGIN, user
});

/** returns action for user fetch success */
export const fetchUserSuccess = user => ({
    type: USER_FETCH_SUCCESS,
    payload: { user }
});

/** returns action for user fetch failed */
export const fetchUserFailure = error => ({
    type: USER_FETCH_FAILURE,
    payload: { error }
});