import React, {} from 'react';
import FormContext from './Context';
import FormControl from '@mui/material/FormControl';
import { TextField, FormHelperText } from '@mui/material';

const Input = ({ label, value, name, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }
    
    return (
        <FormContext.Consumer>
            {context => {
                const errors = context? context.errors: {};
                const errMsgShow = context? context.errMsgShow: true;
                return <>
                <FormControl fullWidth>
                    <TextField
                        label={label}
                        value={value || ''}
                        variant="outlined"
                        name={name}
                        fullWidth
                        onChange={changeHandler}
                        error={errors && errors.details && errors.details[name]? true: false}
                        {...props}
                    />
                {(errMsgShow && errors.details && errors.details[name]) && 
                    <FormHelperText error>
                        {errors.details[name]}
                    </FormHelperText>
                    }
                    </FormControl>
                </>
            }}
        </FormContext.Consumer>
    )
}
export default Input;