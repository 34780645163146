import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const PresentationLayout = props => {
    const { children } = props;

    return <Fragment>{children}</Fragment>;
};

PresentationLayout.propTypes = {
    children: PropTypes.node
};

export default PresentationLayout;
