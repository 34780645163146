import './form.css';
import React from 'react';
import FormContext from './Context';
import { Button, CircularProgress } from '@mui/material';

const LoadingButton = ({ label, onChange, ...props}) => {

    return <FormContext.Consumer>
        {context => {
            return <Button color="primary" variant="contained" size="large" className="my-2" type="button" startIcon={<CircularProgress size={20} />} >
                {label? label: 'Processing'}
            </Button>
        }}
    </FormContext.Consumer>
}
export default LoadingButton;