import { setDefaultLanguage } from "../actions/commonActions";

const initialState = {
    i18nextLng: localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng") : 'en'
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case setDefaultLanguage:
            return {
                ...state,
                i18nextLng: action.data
            };
        default:
            return state;
    }
}