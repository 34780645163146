import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CanPermit = (props) => {
    const couldShow = props.permission ? props.permissions.includes(props.permission) : true;
    return (couldShow || props.app_user === 1) ? props.children : null;
};

CanPermit.propTypes = {
    permissions: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    permissions: state.user.user.permissions,
    app_user: state.user.user.app_user
});

export default connect(mapStateToProps)(CanPermit);