import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const MinimalLayout = props => {
    const { children } = props;
    return <Fragment>{children}</Fragment>;
};

MinimalLayout.propTypes = {
    children: PropTypes.node
};
export default MinimalLayout;