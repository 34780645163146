import React, { Fragment } from 'react';
import { Bell, Activity } from 'react-feather';
import { IconButton, Box, Tooltip } from '@mui/material';

export default function SidebarFooter() {
    return (
        <Fragment>
            <Box className="app-sidebar-footer-wrapper">
                <ul className="app-sidebar-footer">
                    <li>
                        <Tooltip arrow title="Projects Application">
                            <IconButton href="/ApplicationsProjects">
                                <Activity />
                            </IconButton>
                        </Tooltip>
                    </li>
                </ul>
            </Box>
        </Fragment>
    );
}
