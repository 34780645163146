import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const AuthRoute = props => {
    if(props.user && props.user.loggedIn) {
        return props.children;
    } else {
        return (localStorage.getItem("dt_user")) ? null : <Redirect to="/login" />
    }
};

var mapStateToProps = state => {
    return { user: state.user.user }
};
export default connect(mapStateToProps)(AuthRoute);