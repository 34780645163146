import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import React, {  Fragment, useRef, useState } from 'react';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Grid, Fab, Container, InputAdornment, Drawer, IconButton, TextField, Divider } from '@mui/material';

const HeaderSearch = () => {
    const searchRef = useRef(null);
    const history = useHistory();

    const eachli = {
        backgroundColor: "#f8f9ff",
        margin: "10px 0px",
        padding: "15px 10px",
        fontSize: "18px",
        cursor:"pointer"
    }
  
    const Searches = [
        { rte: 'dashboard', pge: "Dashboard" },
        { rte: 'queue', pge: "Queue" },
        { rte: 'role', pge: "Role" },
        { rte: 'company', pge: "Company" },
        { rte: 'user', pge: "User" },
        { rte: 'permission', pge: "Permission" },
        { rte: 'change-password', pge: "Change Password" },
        { rte: 'profile', pge: "Profile" },
        { rte: 'file/create', pge: "File" },
    ];

    const [ Searches2 ,setSearches2 ] = useState([]);

    const handleSearchChange = event => {
        setSearchValue(event.target.value);
        let targetValue = (event.target.value).toLowerCase();

        let filteredSearches = [];

        if (targetValue.includes('user') || targetValue.includes('admin') || targetValue.includes('manager')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('User')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('queue') || targetValue.includes('admin') || targetValue.includes('manager')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('Queue')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('role') || targetValue.includes('admin') || targetValue.includes('manager')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('Role')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('user') || targetValue.includes('change') || targetValue.includes('password')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('Change Password')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('user') || targetValue.includes('profile') || targetValue.includes('change') || targetValue.includes('change')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('Profile')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('file') || targetValue.includes('admin')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('File')));
            filteredSearches.push(filteredSearch[0]);
        }
        if (targetValue.includes('company') || targetValue.includes('admin')) {
            let filteredSearch = Searches.filter(each => (each.pge.includes('Company')));
            filteredSearches.push(filteredSearch[0]);
        }

        setSearches2(filteredSearches);
        if (!openSearchResults) {
            setOpenSearchResults(true);
        }
    };

    const [openSearchResults, setOpenSearchResults] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [state, setState] = useState(false);

    const toggleDrawer = () => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(!state);
    };

    const newFunc = (e,url) => {
        e.preventDefault();
        if (openSearchResults) {
            setState(!state);
            history.push(url);
            setSearchValue('');   
            setOpenSearchResults(false); 
        }
    };

    return (
        <Fragment>
            <IconButton size="medium" onClick={toggleDrawer(true)} color="inherit" className="btn-inverse font-size-xs">
                <SearchIcon />
            </IconButton>
            <Drawer anchor="top" open={state} onClose={toggleDrawer(false)}>
                <Container maxWidth="lg" className="py-4">
                    <div className="d-flex justify-content-between">
                        <div className="text-black">
                            <h1 className="display-3 mb-2 font-weight-bold">Search</h1>
                            <p className="font-size-lg text-black-50">
                                Use the fields below to see search results
                            </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <Fab onClick={toggleDrawer(false)} size="medium" color="primary">
                                <CloseTwoToneIcon />
                            </Fab>
                        </div>
                    </div>
                </Container>
                <div className="app-search-wrapper" ref={searchRef}>
                    <Container maxWidth="lg">
                        <TextField
                            className="app-search-input"
                            fullWidth
                            id="Searchinput"
                            value={searchValue}
                            onChange={handleSearchChange}
                            inputProps={{ 'aria-label': 'search' }}
                            label="Search…"
                            placeholder="Search terms here…"
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon className="app-search-icon" />
                                </InputAdornment>
                              )
                            }}
                        />
                    </Container>
                </div>
                <Container maxWidth="lg" className="pb-3">
                    <div
                        className={clsx('no-search-results', {
                          'search-results-hidden': openSearchResults
                        })}>
                        <div>
                            <div className="text-warning font-weight-bold font-size-xl">
                                No search results!
                            </div>
                            <p className="mb-0 font-size-lg text-black-50">
                                Use the field above to begin searching for something
                            </p>
                        </div>
                    </div>
                    <div
                      className={clsx('search-results', {
                        'search-results-hidden': !openSearchResults
                      })}>
                        <div className="text-black py-4">
                            <h3 className="font-size-xl mb-2 font-weight-bold">
                                Search results
                            </h3>
                            <p className="text-black-50 font-size-lg">
                                These are your results for{' '}
                                <b className="text-black">{searchValue}</b>
                            </p>
                          <Divider />
                        </div>
                        <Grid container spacing={3}>
                            <Grid sm={6} item>
                                {Searches2.map((search, i) => {
                                    return (
                                        <div className='eachli' style={eachli} onClick={e => {newFunc(e,`/${search.rte}`)}} key={i}>
                                          <p style={{marginTop: "10px"}}><ArrowForwardIosIcon/> {search.pge}</p>
                                        </div>
                                    );
                                })}
                                {Searches2.length === 0 ? <div>
                                  <div className="text-warning font-weight-bold font-size-xl">
                                    No search results!
                                  </div>
                                </div>:<></>}
                            </Grid>
                          <Grid sm={6} item style={{fontSize: "25px"}}>
                              Quick Links    
                              {Searches.map((each, i) => {
                                    return (
                                        <div className='eachli' style={eachli} onClick={e => {newFunc(e,`/${each.rte}`)}} key={i}>
                                          <p style={{marginTop: "10px"}}><ArrowForwardIosIcon/> {each.pge}</p>
                                        </div>
                                    );
                                  })
                                  }
                          </Grid>
                        </Grid>
                    </div>
                </Container>
            </Drawer>
        </Fragment>
    );
};

export default HeaderSearch;
