import FormContext from './Context';
import React, { useState } from 'react';

const ContextForm = ({ errMsgShow, errors, onSubmit, children, method="POST", style }) => {
    let [ isSubmit, setSubmit ] = useState(false);
    errors = errors? errors: {};
    errMsgShow = errMsgShow? errMsgShow: true;

    const handleSubmit = e => {
        if(isSubmit) {
            setSubmit(true);
        }
        e.preventDefault();
        onSubmit();
    }
    if(isSubmit) {
        errors = {};
    }

    return (
        <form method={method} onSubmit={handleSubmit} style={style}>
            <FormContext.Provider value={{ errMsgShow, errors }}>
                {children}
            </FormContext.Provider>
        </form>
    )
}
export default ContextForm;