import UserReducer from './UserReducer';
import ThemeOptions from './ThemeOptions';
import ErrorReducer from './ErrorReducer';
import DashboardReducer from './DashboardReducer';
import CommonReducer from './CommonReducer'

export default {
    ThemeOptions, user: UserReducer, dashboard: DashboardReducer, common: CommonReducer,
    error: ErrorReducer
};
