import React from 'react';
import PropTypes from 'prop-types';
import useRouter from 'utils/useRouter';
import { List, Typography } from '@mui/material';
import SidebarMenuListItem from './SidebarMenuListItem';
import { matchPath,useLocation } from 'react-router-dom';

const SidebarMenuList = props => {
    const { pages, ...rest } = props;
    const loc = useLocation();

    return (
        <List className="p-0">
            {pages.reduce(
                (items, page) => reduceChildRoutes({ items, page,loc, ...rest }),
                []
            )}
        </List>
    );
};

SidebarMenuList.propTypes = {
    depth: PropTypes.number,
    pages: PropTypes.array
};

const reduceChildRoutes = props => {
    const { router, items, page,loc, depth } = props;
    if (page.content) {
        const open = matchPath(loc.pathname, {
            path: page.to,
            exact: false
        });

        items.push(
            <SidebarMenuListItem
              depth={depth}
              icon={page.icon}
              key={page.label}
              label={page.badge}
              open={Boolean(open)}
              title={page.label}>
              <div className="sidebar-menu-children py-2">
                  <SidebarMenuList depth={depth + 1} pages={page.content} router={router} />
              </div>
            </SidebarMenuListItem>
        );
    } else {
        items.push(
            <SidebarMenuListItem
                depth={depth}
                href={page.to}
                icon={page.icon}
                key={page.label}
                label={page.badge}
                title={page.label}
            />
        );
    }

    return items;
};

const SidebarMenu = props => {
    const { title, pages, className, component: Component, ...rest } = props;
    const router = useRouter();

    return (
        <Component {...rest} className={className}>
            {title && (
                <Typography className="app-sidebar-heading">{title}</Typography>
            )}
            <SidebarMenuList depth={0} pages={pages} router={router} />
        </Component>
    );
};

SidebarMenu.propTypes = {
    className: PropTypes.string,
    component: PropTypes.any,
    pages: PropTypes.array.isRequired,
    title: PropTypes.string
};

SidebarMenu.defaultProps = {
    component: 'nav'
};

export default SidebarMenu;
