import React from 'react';
import { connect } from "react-redux";
import ErrorFallback from './ErrorFalback';

const NetworkError = props => {

    if(props.error && Object.keys(props.error).length > 0) {
        return <ErrorFallback />
    }
    return props.children;
}
const mapStateToProps = state => {
    return { error: state.error.appError }
};
export default connect(mapStateToProps)(NetworkError)