class TokenService {
    
    getLocalRefreshToken() {
        const user = JSON.parse(localStorage.getItem("dt_user"));
        return user.refresh_token;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem("dt_user"));
        return user?.access_token;
    }

    getLanguage() {
        const user = JSON.parse(localStorage.getItem("dt_user"));
        return user?.language;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem("dt_user"));
        user.access_token = token;
        localStorage.setItem("dt_user", JSON.stringify(user));
    }

    updateLocalToken(accessToken, refreshToken) {
        let user = JSON.parse(localStorage.getItem("dt_user"));
        user.access_token = accessToken;
        user.refresh_token = refreshToken;
        localStorage.setItem("dt_user", JSON.stringify(user));
    }

    updateLanguage(language) {
        let user = JSON.parse(localStorage.getItem("dt_user"));
        user.language = language;
        localStorage.setItem("dt_user", JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem("dt_user"));
    }

    getLanguages() {
        return JSON.parse(localStorage.getItem("dt_languages"));
    }

    setUser(data) {
        let { languages, ...user } = data;
        delete user.userCompanyList;
        delete user.permissions;
        delete user.role;
        delete user.company;
        localStorage.setItem("dt_user", JSON.stringify(user));
        localStorage.setItem("dt_languages", JSON.stringify(languages));
    }

    removeUser() {
        localStorage.removeItem("dt_user");
        localStorage.removeItem("dt_languages");
    }
}

export default new TokenService();