import React from 'react';
import './datatable.css';
import { Button } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useTranslation } from 'react-i18next';

const DataTable = props => {
    const { t } = useTranslation(['datatable']);
    let filter = props.filter ? props.filter : false;
    let serverSide = props.serverSide ? true : false;
    let allowCall = true;

    const tableChangeEvent = (action, tableState) => {
        if (!serverSide) {
            return true;
        }
        if (action === 'onFilterDialogClose') {
            allowCall = true
        }

        let allowedEvents = ['changePage', 'sort', 'filterChange', 'onFilterDialogClose', 'changeRowsPerPage', 'rowSelectionChange'];
        if (allowedEvents.includes(action) && allowCall) {
            props.remoteCall(action, tableState);
        }
    }
    return (
        <MUIDataTable
            data={props.rows}
            columns={props.columns}
            options={{
                serverSide,
                download: false, filter,
                filterType: 'textField', print: false,
                viewColumns: false, selectableRows: props.selectableRows || 'none',
                customToolbarSelect: selectedRows => {
                    return (
                        <div style={{ padding: '10px' }}>
                            <Button variant="contained" onClick={() => { props.showSelectedRows(selectedRows) }}>View Details</Button>
                        </div>
                    )
                },
                sortOrder: (props.sort && props.sortField) ? { name: props.sortField, direction: props.sort } : {},
                count: props.rowCount ? props.rowCount : 0,
                page: props.page ? props.page : 0,
                rowsPerPageOptions: props.rowsPerPageOptions ? props.rowsPerPageOptions : [10, 25, 100],
                rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 10,
                onTableChange: tableChangeEvent,
                confirmFilters: true,
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => {
                                allowCall = false;
                                applyNewFilters();
                            }
                            }>{t('apply_filters')}</Button>
                        </div>
                    );
                },
                textLabels: {
                    body: {
                        noMatch: t('datatable:no_records_found'),
                        toolTip: t('datatable:sort'),
                        columnHeaderTooltip: column => `${t('datatable:sort_for')} ${column.label}`
                    },
                    pagination: {
                        next: t('datatable:next_page'),
                        previous: t('datatable:prev_page'),
                        rowsPerPage: t('datatable:rows_per_page'),
                        displayRows: t('datatable:of'),
                    },
                    toolbar: {
                        search: t('datatable:search'),
                        downloadCsv: t('datatable:download_csv'),
                        print: t('datatable:print'),
                        viewColumns: t('datatable:view_columns'),
                        filterTable: t('datatable:filter_table'),
                    },
                    filter: {
                        all: t('datatable:all'),
                        title: t('datatable:filters'),
                        reset: t('datatable:reset'),
                    },
                    viewColumns: {
                        title: t('datatable:show_columns'),
                        titleAria: t('datatable:show_hide_columns'),
                    },
                    selectedRows: {
                        text: t('datatable:rows_selected'),
                        delete: t('datatable:delete'),
                        deleteAria: t('datatable:deleted_rows'),
                    },
                }
            }}
        />
    );
}
export default DataTable;