import React from 'react';
import FormContext from './Context';
import FormControl from '@mui/material/FormControl';
import { TextField, FormHelperText, MenuItem } from '@mui/material';

const Select = ({ label, value, name, options, onChange, ...props}) => {

    function changeHandler(e) {
        onChange(e.target.name, e.target.value);
    }

    return (
        <FormContext.Consumer>
            {context => {
                const errors = context? context.errors: {};
                const errMsgShow = context? context.errMsgShow: true;
                return <FormControl fullWidth>
                    <TextField
                        select
                        label={label || ''}
                        defaultValue=""
                        value={ (value && value !== '0')? value: "" }
                        name={name}
                        fullWidth
                        variant="outlined"
                        onChange={changeHandler}
                        error={errors && errors.details && errors.details[name]? true: false}
                        {...props}>
                            {options.length > 0? options.map((option, i) => (
                                <MenuItem key={i} value={option.value} >
                                    {option.title}
                                </MenuItem>
                            )) : <MenuItem value="" >Select</MenuItem>}
                    </TextField>
                {(errMsgShow && errors.details && errors.details[name]) && 
                    <FormHelperText error id="email-error">
                        {errors.details[name]}
                    </FormHelperText> }
                </FormControl>
            }}
        </FormContext.Consumer>
    )
}
export default Select;