import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Permit } from '../../pages/common';
import AddIcon from '@mui/icons-material/Add';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Paper, Box, Typography, Fab, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={4}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const PageTitle = props => {
    const { t } = useTranslation();

    let { createLink, tooltip, breadcrumbs, description, titleHeading, backLink, permission } = props;

    const renderCreateButton = () => {
        return <Permit permission={permission}>
            <div className="speedial-wrapper">
                <Tooltip arrow title={tooltip}>
                    <Link to={createLink} style={{ color: 'white' }}>
                        <Fab color="primary" aria-label="add" className="m-2">
                            <AddIcon />
                        </Fab>
                    </Link>
                </Tooltip>
            </div>
        </Permit>
    }

    return (
        <Fragment>
            <Paper square elevation={2} className='app-page-title'>
                <div>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} className="mb-2" maxItems={3} aria-label="breadcrumb">
                        <Link color="inherit" to="/">
                            {t('home')}
                        </Link>
                        {breadcrumbs && breadcrumbs.length > 0 && breadcrumbs.map((bc, i) => {
                            return <Link color="inherit" to={bc.link} key={i}>
                                {bc.title}
                            </Link>
                        })}
                        <Typography color="textPrimary">{titleHeading}</Typography>
                    </Breadcrumbs>

                    <Box className="app-page-title--first">
                        <div className="app-page-title--heading">
                            <h1>{titleHeading}</h1>
                            {description && (
                                <div className="app-page-title--description">
                                    {description}
                                </div>
                            )}
                        </div>
                    </Box>
                </div>
                {createLink && renderCreateButton()}
                {backLink && <div className="speedial-wrapper">
                    <Tooltip arrow title={tooltip}>
                        <Link to={backLink} style={{ color: 'white' }}>
                            <Fab color="primary" aria-label="add" className="m-2">
                                <ArrowBackIcon />
                            </Fab>
                        </Link>
                    </Tooltip>
                </div>}
            </Paper>
        </Fragment>
    );
}
export default PageTitle