import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

const SignRoute = props => {
    if(props.user && props.user.loggedIn) {
        return <Redirect to="/dashboard" />
    } else {
        return props.children;
    }
};

var mapStateToProps = state => ({ user: state.user.user });
export default connect(mapStateToProps)(SignRoute);