import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import avatar7 from '../../assets/images/avatars/avatar7.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { Avatar, Hidden, IconButton, Box, Typography, Popover, Badge, Button, } from '@mui/material';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default function HeaderDots() {
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const handleClick1 = event => setAnchorEl1(event.currentTarget)
    const handleClose1 = () => setAnchorEl1(null)
    const handleClose2 = () => setAnchorEl2(null)
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);

    return (
        <Fragment>
            <div className="d-flex align-items-center popover-header-wrapper">
                <Hidden smDown>
                    <Box component="span" pr="2">
                        <IconButton onClick={handleClick1} color="inherit" className="btn-inverse mx-1 d-50">
                            <div className="badge badge-pill badge-warning badge-header"> 0 </div>
                            <NotificationsActiveTwoToneIcon />
                        </IconButton>
                        <Popover
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: 'app-header-dots'
                            }}>
                            <div className="popover-custom-xl overflow-hidden">
                                <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                                    <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                                    <div className="bg-composed-wrapper--content text-light px-2 py-4">
                                        <h4 className="font-size-xl font-weight-bold mb-2">
                                            Notifications
                                        </h4>
                                        <p className="opacity-8 mb-0">
                                            You have <b className="text-success">0</b> new messages
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Popover>
                    </Box>
                </Hidden>
                <Box component="span" pr="2">
                    <Popover
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}>
                        <ul className="list-group list-group-flush text-left bg-transparent">
                            <li className="list-group-item rounded-top">
                                <div className="align-box-row">
                                    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                                        <Avatar alt="Travis Howard" src={avatar7} />
                                    </Badge>
                                    <div className="pl-2">
                                        <span className="pb-1 d-block">Emma Taylor</span>
                                        <small className="pb-1 text-black-50 d-block">
                                            This is an accountant profile
                                        </small>
                                        <div className="divider my-2"></div>
                                        <small className="text-black-50">
                                            Status: <b className="text-danger">Overdue</b>
                                        </small>
                                    </div>
                                </div>
                            </li>
                            <li className="list-group-item bg-transparent py-2">
                                <div className="align-box-row mb-1">
                                    <div>
                                        <small className="font-weight-bold">
                                            Profile completion
                                        </small>
                                    </div>
                                </div>
                                <div className="progress w-100">
                                    <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="43" className="progress-bar bg-success w-43" role="progressbar"></div>
                                </div>
                                <div className="align-box-row progress-bar--label mt-1 text-muted">
                                    <small className="text-dark">0</small>
                                    <small className="ml-auto">100%</small>
                                </div>
                            </li>
                        </ul>
                        <div className="card-footer bg-white p-3 text-center d-block">
                            <Button variant="outlined" size="small" className="mr-2" color="primary">
                                <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={['far', 'keyboard']} />
                                </span>
                                <span className="btn-wrapper--label">Tasks</span>
                            </Button>
                            <Button variant="outlined" size="small" color="default">
                                <span className="btn-wrapper--icon">
                                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                                </span>
                                <span className="btn-wrapper--label">Logout</span>
                            </Button>
                        </div>
                    </Popover>
                </Box>
            </div>
        </Fragment>
    );
}
