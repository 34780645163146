import clsx from "clsx";
import navItems from "./navItems";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import SidebarMenu from "../../layout/SidebarMenu";
import { Hidden, Drawer, Paper } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarHeader from "../../layout/SidebarHeader";
import SidebarUserbox from "../../layout/SidebarUserbox";
import { setSidebarToggleMobile, setSidebarHover, setSidebarToggle, setSidebarFooter, setSidebarUserbox, } from "../../reducers/ThemeOptions";

const Sidebar = (props) => {
    const {
        setSidebarToggleMobile,
        sidebarToggleMobile,
        sidebarFixed,
        sidebarHover,
        setSidebarHover,
        sidebarToggle,
        sidebarUserbox,
        sidebarShadow,
        user
    } = props;

    let items = navItems(user.permissions, user.app_user);
    const toggleHoverOn = () => setSidebarHover(true);
    const toggleHoverOff = () => setSidebarHover(false);

    const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

    const sidebarMenuContent = (
        <div className={clsx({ "app-sidebar-nav-close": sidebarToggle && !sidebarHover })}>
            {items.length > 0 && items.map((list, i) => <SidebarMenu component="div" key={i} pages={list.content} title={list.label} />)}
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer anchor="left" open={sidebarToggleMobile} onClose={closeDrawer} variant="temporary" elevation={4} className="app-sidebar-wrapper-lg">
                    <SidebarHeader />
                    <PerfectScrollbar>
                        {sidebarUserbox && <SidebarUserbox />}
                        {sidebarMenuContent}
                    </PerfectScrollbar>
                </Drawer>
            </Hidden>

            <Hidden mdDown>
                <Paper
                    onMouseEnter={toggleHoverOn}
                    onMouseLeave={toggleHoverOff}
                    className={clsx("app-sidebar-wrapper", {
                        "app-sidebar-wrapper-close": sidebarToggle,
                        "app-sidebar-wrapper-open": sidebarHover,
                        "app-sidebar-wrapper-fixed": sidebarFixed,
                    })}
                    square
                    open={sidebarToggle}
                    elevation={sidebarShadow ? 11 : 3}>
                    <SidebarHeader />
                    <div
                        className={clsx({
                            "app-sidebar-menu": sidebarFixed,
                            "app-sidebar-collapsed": sidebarToggle && !sidebarHover,
                        })}>
                        <PerfectScrollbar options={{ wheelPropagation: false }}>
                            {sidebarUserbox && <SidebarUserbox />}
                            {sidebarMenuContent}
                        </PerfectScrollbar>
                    </div>
                </Paper>
            </Hidden>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    headerFixed: state.ThemeOptions.headerFixed,
    sidebarToggle: state.ThemeOptions.sidebarToggle,
    sidebarHover: state.ThemeOptions.sidebarHover,
    sidebarShadow: state.ThemeOptions.sidebarShadow,
    sidebarFooter: state.ThemeOptions.sidebarFooter,
    sidebarUserbox: state.ThemeOptions.sidebarUserbox,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
    user: state.user.user
});

const mapDispatchToProps = dispatch => ({
    setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
    setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
    setSidebarHover: enable => dispatch(setSidebarHover(enable)),
    setSidebarFooter: enable => dispatch(setSidebarFooter(enable)),
    setSidebarUserbox: enable => dispatch(setSidebarUserbox(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);