import React, { Fragment } from 'react';
import { useHistory } from "react-router-dom";
import TokenService from '../../services/token.service';
import avatar4 from '../../assets/images/avatars/avatar4.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Menu, Button, List, ListItem, Divider } from '@mui/material';//Badge
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { fetchUserBegin } from "../../actions/userActions";

const HeaderUserbox = (props) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory();
    const logoName = `${localStorage.first_name} ${localStorage.lastname}`;

    const handleClick = event => setAnchorEl(event.currentTarget)
    const onClick = (value) => {
        setAnchorEl(null);
        history.push(value)
    };
    const handleClose = () => setAnchorEl(null)
    const logout = async () => {
        TokenService.removeUser();
        props.fetchUserBegin({});
    }

    return (
        <Fragment>
            <Button color="inherit" onClick={handleClick} className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
                <Box>
                    <Avatar sizes="44" alt={logoName} src={props.user.image} />
                </Box>
                <div className="d-none d-xl-block pl-3">
                    <div className="font-weight-bold pt-2 line-height-1">

                    </div>
                    <span className="text-white-50"></span>
                </div>
                <span className="pl-1 pl-xl-3">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} />
                </span>
            </Button>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                onClose={handleClose}
                className="ml-2">
                <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
                    <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
                        <Box>
                           <Avatar sizes="44" alt="Dustin Watson" src={props.user.image || avatar4} />
                        </Box>
                        <div className="pl-3 ">
                            <div className="font-weight-bold text-center pt-2 line-height-1">
                                {JSON.parse(localStorage.getItem('dt_user')).first_name} {JSON.parse(localStorage.getItem('dt_user')).last_name}
                            </div>
                        </div>
                        <Divider className="w-100 mt-2" />
                        <ListItem type="button" onClick={() => onClick('/change-password')}>{t('change_password')}</ListItem>
                        <ListItem type="button" onClick={() => onClick('/profile')}>{t('profile')}</ListItem>
                        {/* <ListItem type="button" onClick={() => onClick('/themes')}>{t('themes')}</ListItem> */}
                        <ListItem type="button" onClick={logout}>{t('logout')}</ListItem>
                    </List>
                </div>
            </Menu>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.user.user
});
const mapDispatchToProps = (dispatch) => ({
    fetchUserBegin: (user) => dispatch(fetchUserBegin(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);