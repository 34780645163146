import clsx from "clsx";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import HeaderLogo from "../../layout/HeaderLogo";
// import HeaderDots from "../../layout/HeaderDots";
import HeaderUserbox from "../../layout/HeaderUserbox";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import projectLogo from "../../assets/images/logo/deeptrace01.png";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import { Hidden, IconButton, AppBar, Box, Tooltip } from "@mui/material";
import { setSidebarToggle, setSidebarToggleMobile, } from "../../reducers/ThemeOptions";
import { fetchUserBegin } from "../../actions/userActions";
import { Select } from "../../theme/Form";
import TokenService from "../../services/token.service";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import { postCall } from "api";
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect } from "react";

const Header = (props) => {
	const { t } = useTranslation();
	const logoRef = useRef(null)
	const history = useHistory()
	const toggleSidebar = () => setSidebarToggle(!sidebarToggle);
	const toggleSidebarMobile = () => setSidebarToggleMobile(!sidebarToggleMobile);
	const {
		headerShadow,
		headerFixed,
		sidebarToggleMobile,
		setSidebarToggleMobile,
		setSidebarToggle,
		sidebarToggle,
		isCollapsedLayout,
		user,
	} = props;

	const companyHandler = async (name, value) => {
		// fetch permissions from API for selected company and redirect to dashboard
		const userTemp = JSON.parse(JSON.stringify(user));
		const index = user.userCompanyList.findIndex((item) => {
			return item.id === value;
		});
		userTemp.company_id = user.userCompanyList[index].id;
		userTemp.role_id = user.userCompanyList[index].uc_role_id;
		userTemp.logo = user.userCompanyList[index].logo;
		userTemp.theme_color = user.userCompanyList[index].theme_color;

		const permission = await AuthService.getPermissions(userTemp.company_id, userTemp.role_id)
		userTemp.permissions = permission

		TokenService.setUser(userTemp);
		props.fetchUserBegin(userTemp);
		history.push("/dashboard");
	};

	const languageHandler = async (e, value) => {
		// update language preference for logged in user
		let resp = await postCall('user/update-language', {
			language: value
		}, true);

		if (resp.success) {
			if (resp.message === 'PROFILE_UPDATED') { }
		}

		const userTemp = JSON.parse(JSON.stringify(user));
		userTemp.language = value
		TokenService.setUser(userTemp);
		props.fetchUserBegin(userTemp);
	}

	return (
		<Fragment>
			<AppBar
				color="secondary"
				className={clsx("app-header", {
					"app-header-collapsed-sidebar": isCollapsedLayout,
				})}
				position={headerFixed ? "fixed" : "absolute"}
				elevation={headerShadow ? 11 : 3}
			>
				{!isCollapsedLayout && <HeaderLogo />}
				<Box className="app-header-toolbar">
					<Hidden lgUp>
						<Box className="app-logo-wrapper" title="WINT">
							<Link to="/dashboard" className="app-logo-link">
								{/* app-logo-btn */}
								<IconButton color="primary" size="medium" className="">
									<img className="app-logo-img" alt="WINT" src={projectLogo} />
									{/* DeepTrace */}
								</IconButton>
							</Link>
							<Hidden smDown>
								<Box className="app-logo-text">WINT</Box>
							</Hidden>
						</Box>
						<Box className="" style={{ display: 'grid' }}>
							<Select
								options={[
									{
										value: 'en',
										title: 'English',
									},
									{
										value: 'de',
										title: 'German',
									}
								]}
								onChange={languageHandler}
								className="m-2"
								label={t('language')}
								value={user.language}
							/>
						</Box>
					</Hidden>
					<Hidden mdDown>
						<Box className="d-flex align-items-center">
							{!isCollapsedLayout && (
								<Box
									className={clsx("btn-toggle-collapse", {
										"btn-toggle-collapse-closed": sidebarToggle,
									})}
								>
									<Tooltip title="Toggle Sidebar" placement="right">
										<IconButton
											color={!sidebarToggle ? "primary" : "inherit"}
											onClick={toggleSidebar}
											size="medium"
											className="btn-inverse"
										>
											{sidebarToggle ? (
												<MenuRoundedIcon />
											) : (
												<MenuOpenRoundedIcon />
											)}
										</IconButton>
									</Tooltip>
								</Box>
							)}

							{/* Select language to access site */}
							<Box className="">
								<Select
									options={[
										{
											value: 'en',
											title: 'English',
										},
										{
											value: 'de',
											title: 'German',
										}
									]}
									onChange={languageHandler}
									className="m-2"
									label={t('language')}
									value={user.language}
								/>
							</Box>
						</Box>
					</Hidden>
					<Box className="d-flex align-items-center">
						<Hidden mdDown>
							{/* select company from assigned company to logged in user */}
							{user.app_user === 2 || user.app_user === 3 ? (
								<>
									{user.userCompanyList.length === 1 ? (user.userCompanyList[0].title + " - " + user.userCompanyList[0].role_title) : (
										<Select
											options={user.userCompanyList.map((com) => ({
												value: com.id,
												title: com.title + ' - ' + com.role_title,
											}))}
											onChange={companyHandler}
											className="m-2"
											label={t('company')}
											value={user.company_id}
										/>
									)}
								</>
							) : user.role_title}
							&nbsp;
						</Hidden>
						{/* <HeaderDots /> */}
						<HeaderUserbox />
						<Box className="toggle-sidebar-btn-mobile">
							<Tooltip title="Toggle Sidebar" placement="right">
								<IconButton
									color="inherit"
									onClick={toggleSidebarMobile}
									size="medium"
								>
									{sidebarToggleMobile ? (
										<MenuOpenRoundedIcon />
									) : (
										<MenuRoundedIcon />
									)}
								</IconButton>
							</Tooltip>
						</Box>
					</Box>
				</Box>

				{/* <Box className="d-flex align-items-center">
					<Select
						options={[
							{
								value: 'en',
								title: 'English',
							},
							{
								value: 'de',
								title: 'German',
							}
						]}
						onChange={languageHandler}
						className="m-2"
						label={t('language')}
						value={user.language}
						InputLabelProps={{
							style: { color: "#fff" },
						}}
						InputProps={InputProps}
					/>
				</Box> */}
			</AppBar>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	user: state.user.user,
	headerShadow: state.ThemeOptions.headerShadow,
	headerFixed: state.ThemeOptions.headerFixed,
	sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
	sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserBegin: (user) => dispatch(fetchUserBegin(user)),
	setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
	setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
